<template>
  <b-modal
    :visible="showModalCupom"
    centered
    hide-header-close
    id="modal-center"
    @hide="hideModal"
    size="sm"
    :hide-footer="true"
  >
    <div class="row text-center">
      <div class="col-12">
        <ValidationProvider name="cupom" v-slot="{ errors, classes }">
          <input
            type="text"
            :readonly="cupomAplicado"
            class="form-control5"
            :class="classes"
            v-model="cupomDesconto"
            autocomplete="nope"
            placeholder="Insira o Cupom"
          />
          <span class="invalid-feedback">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
      <div class="col-12 mt-2">
        <b-button
          v-if="!cupomAplicado"
          :disabled="!cupomDesconto"
          class="bike-btn"
          variant="success"
          @click.prevent="onClickAplicarCupom()"
          >Aplicar</b-button
        >
        <b-button
          v-else
          class="bike-btn"
          variant="danger"
          @click.prevent="removerDesconto()"
          >Remover</b-button
        >
      </div>
      <div @click.prevent="hideModal()" class="col-12 mt-2">
        <span class="voltar">voltar</span>
      </div>
    </div>
  </b-modal>
</template>
<script>
export default {
  props: ["showModalCupom", "cupomAplicado","limpar"],
  data() {
    return {
      modificou: false,
      cupomDesconto: "",
    };
  },
  watch: {
    limpar: function(newValue) {
      if (newValue) {
        this.cupomDesconto ="";
      }
    },
  },
  methods: {
    onClickAplicarCupom() {
      let data = { cupom: this.cupomDesconto };
      this.aplica= true;
      this.$emit("aplicar", data);
    },
    removerDesconto() {
      this.$emit("remove");
       this.aplica= true;
    },
    hideModal() {
       this.aplica= true;
      this.$emit("hide");
    },
  },
};
</script>

<style scoped>
.modal-header {
  border-bottom: 0px solid #ffffff;
}
.voltar {
  color: #9a9a9a;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  cursor: pointer;
}

.form-control5::placeholder {
  /* Adiciona uma sombra suave quando o input é selecionado */
  outline: none;
  color: #9a9a9a;
}
.form-control5:focus {
  /* Adiciona uma sombra suave quando o input é selecionado */
  color: #454545;
  outline: none;
  background: #fff;
}
.form-control5 {
  padding-left: 14px;
  height: 56px;
  flex-shrink: 0;
  color: #9a9a9a;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border-radius: 17px;
  border: 1px solid #e9e9e9;
  background: #fafafa;
}
.bike-btn {
  width: 167px;
  height: 46px;
  border-radius: 25px;
  border: 1px;
  background: #089f17;
  border: 1px solid #089f17;
  margin-top: 10px;
}
</style>
